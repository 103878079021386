import classnames from 'classnames/bind'
import { m } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useMeasure } from 'react-use'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import LoadingComponent from '~/components/Abstracts/LoadingComponent'
import VideoPlayer, {
  useVideoState,
  VideoPlayerProps,
} from '~/components/Abstracts/VideoPlayer'
import WrapperWithLink from '~/components/Abstracts/WrapperWithLink'
import Image, { ImageProps } from '~/components/UI/Image'
import Link, { LinkProps } from '~/components/UI/Link'

import { useMathFitStyle } from '~/hooks/useMathFit'
import useParallax, {
  useGetParallaxStyleFromMaxTranslateAndHeight,
} from '~/hooks/useParallax'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ ratio: 24 / 24 }]

export enum ItemTypes {
  Video = 'video',
  Image = 'image',
}

interface SliderCoverItemBaseProps {
  className?: string
  itemClassName?: string
  active?: boolean
  link: LinkProps
  translateY?: number
  isPriority?: boolean
}

export interface ImageItem {
  main?: ImageProps
  mobile?: ImageProps
}

export interface VideoItem {
  main?: VideoPlayerProps
  mobile?: VideoPlayerProps
}

interface ItemVideoProps extends SliderCoverItemBaseProps {
  type: ItemTypes.Video
  item: {
    video?: VideoItem
    cover?: ImageItem
  }
  index?: number
}

interface ItemImageProps extends SliderCoverItemBaseProps {
  type: ItemTypes.Image
  item: ImageItem
  index?: number
}

export type SliderCoverItemProps = ItemVideoProps | ItemImageProps

const MAX_TRANSLATE_Y = 100
export interface SliderCoverProps {
  className?: string
  sliderClassName?: string
  slideClassName?: string
  items?: SliderCoverItemProps[]
  setSliderState?: ReturnType<typeof useSliderState>[1]
  hasSlicePriority?: boolean
}

function ImageItem({
  className,
  itemClassName,
  link,
  item,
  translateY,
  index,
  isPriority,
}: Omit<ItemImageProps, 'type'>) {
  const [containerRef, { height }] = useMeasure()

  const style = useGetParallaxStyleFromMaxTranslateAndHeight({
    y: translateY,
    maxTranslateY: MAX_TRANSLATE_Y,
    height: height,
  })

  const hasCoverMobile = item?.mobile ?? false

  return (
    <WrapperWithLink className={cx(className)} position={`${index}`} {...link}>
      <div ref={containerRef}>
        <div style={style}>
          {item?.main && (
            <Image
              className={cx(css.image, itemClassName, { hasCoverMobile })}
              sizesFromBreakpoints={IMAGE_SIZES}
              layout="fill"
              objectFit="cover"
              asPlaceholder
              priority={isPriority}
              {...item?.main}
            />
          )}

          {item?.mobile && (
            <Image
              className={cx(css.image, css.imageMobile, itemClassName)}
              sizesFromBreakpoints={IMAGE_SIZES}
              layout="fill"
              objectFit="cover"
              asPlaceholder
              priority={isPriority}
              {...item?.mobile}
            />
          )}
        </div>
      </div>
    </WrapperWithLink>
  )
}

function VideoItem({
  className,
  itemClassName,
  link,
  item,
  active,
  isPriority,
}: Omit<ItemVideoProps, 'type'>) {
  const { ref, inView } = useInView({
    threshold: 0,
  })

  const [lastState, setLastState] = useState(false)
  const [initialized, setInitialized] = useState(false)

  const [
    {
      videoProgress,
      videoHandleSeekTo,
      videoIsReady,
      videoIsLoading,
      videoHandlePausePlay,
      videoPaused,
    },
    setVideoState,
  ] = useVideoState()

  const {
    targetRef: videoRef,
    parentRef: linkRef,
    processedCss,
  } = useMathFitStyle({ type: 'cover' }, [videoIsReady])

  // Reset video seeking when leaving slide
  useEffect(() => {
    if (lastState !== active) {
      setLastState(active)
      if (!active) videoHandleSeekTo?.(0)
    }
  }, [active, lastState, videoHandleSeekTo])

  useEffect(() => {
    // initialize video player
    if (inView && !initialized) {
      setInitialized(true)
    }

    // toggle play/pause video when in view
    if (initialized && ((inView && videoPaused) || (!inView && !videoPaused)))
      videoHandlePausePlay?.()
  }, [inView])

  const hasCover = useMemo(
    () => !videoIsReady && !(videoProgress > 0),
    [videoIsReady, videoProgress],
  )
  const hasLoading = useMemo(
    () => hasCover && videoIsLoading,
    [hasCover, videoIsLoading],
  )
  const hasCoverMobile = item?.cover?.mobile ?? false
  const hasVideoMobile = item?.video?.mobile ?? false

  const commonVideoProps = {
    className: css.videoPlayer,
    isAutoplay: lastState,
    controls: false,
    hasPlaysInline: true,
    setVideoState,
    isConstrained: false,
    isMuted: true,
    loop: true,
  }

  return (
    <div ref={ref} className={cx(className)}>
      <div ref={linkRef}>
        <Link {...link} className={css.itemLink}>
          <div className={itemClassName}>
            {item?.video?.main && (
              <m.div
                ref={videoRef}
                style={processedCss}
                className={cx(
                  css.videoPlayerContainer,
                  hasVideoMobile ? css.hideOnSmallScreen : null,
                )}>
                <VideoPlayer {...commonVideoProps} {...item?.video?.main} />
              </m.div>
            )}

            {hasVideoMobile && (
              <m.div
                ref={videoRef}
                style={processedCss}
                className={cx(css.videoPlayerContainer, css.hideOnLargeScreen)}>
                <VideoPlayer {...commonVideoProps} {...item?.video?.mobile} />
              </m.div>
            )}

            {item?.cover?.main && (
              <Image
                className={cx(css.image, { hide: !hasCover, hasCoverMobile })}
                sizesFromBreakpoints={IMAGE_SIZES}
                layout="fill"
                objectFit="cover"
                asPlaceholder
                priority={isPriority}
                {...item?.cover?.main}
              />
            )}

            {item?.cover?.mobile && (
              <Image
                className={cx(css.image, css.imageMobile, { hide: !hasCover })}
                sizesFromBreakpoints={IMAGE_SIZES}
                layout="fill"
                objectFit="cover"
                asPlaceholder
                priority={isPriority}
                {...item?.cover?.mobile}
              />
            )}

            {hasLoading && <LoadingComponent />}
          </div>
        </Link>
      </div>
    </div>
  )
}

const mapItemType: {
  [key in ItemTypes]: (args: Omit<SliderCoverItemProps, 'type'>) => JSX.Element
} = {
  [ItemTypes.Video]: VideoItem,
  [ItemTypes.Image]: ImageItem,
}

function SliderCover({
  className,
  sliderClassName,
  slideClassName,
  items,
  setSliderState,
  hasSlicePriority,
}: SliderCoverProps) {
  const customSliderRef = useRef()

  const [sliderStateProcessed, setSliderStateProcessed] = useSliderState()

  useEffect(() => {
    if (setSliderState) setSliderState(sliderStateProcessed)
  }, [sliderStateProcessed, setSliderState])

  const { y, ref } = useParallax({
    displacement: MAX_TRANSLATE_Y,
  })

  return (
    <div ref={ref} className={cx(css.SliderCover, className)}>
      <Slider
        className={cx(css.slider, sliderClassName)}
        customSliderRef={customSliderRef}
        setSliderState={setSliderStateProcessed}
        snap
        infinite>
        {items?.map(({ type, ...props }, index) => {
          const Item = mapItemType?.[type]
          const active = sliderStateProcessed.slideIndex === index

          return Item ? (
            <Item
              key={`slide_${index}`}
              className={cx(css.slide, slideClassName, css?.[type])}
              itemClassName={css.item}
              active={active}
              translateY={y}
              index={index + 1}
              isPriority={hasSlicePriority && index === 0}
              {...props}
            />
          ) : null
        })}
      </Slider>
    </div>
  )
}

SliderCover.defaultProps = {}

export default SliderCover
